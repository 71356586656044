
.bg-brand {
  background-color: #ff7101!important;
  color: #fff!important;
}

.nav-brand a:hover {
  text-decoration: underline;
}

.bg-brand-primary {
  background-color: #1c8408!important;
  color: #fff!important;
}

.nav-brand a {
  text-decoration: none;
  color: #000000!important;
  font-size: 18px;
  font-weight: 400;
}

.text-brand {
  color: #fff!important;
}

.text-brand-primary {
  color: #ff7101!important;
}

.text-brand-dark {
  color: #000000!important;
}

.text-brand-secondary {
  color: #1c8408!important;
}

.hanging-msg {
  font-size: 26px;
  font-weight: 400;
  background-color: #fdf0e5;
  width: fit-content;
  padding: 20px 60px;
  border-radius: 0 0 100px 100px;
  border: 5px solid #1c8408;
  border-top-color: #ff7101;
  max-width: 60%;
}

#gallery{
  -webkit-column-count:4;
  -moz-column-count:4;
  column-count:4;
  
  -webkit-column-gap:20px;
  -moz-column-gap:20px;
  column-gap:20px;
}
@media (max-width:1200px){
  #gallery{
  -webkit-column-count:3;
  -moz-column-count:3;
  column-count:3;
    
  -webkit-column-gap:20px;
  -moz-column-gap:20px;
  column-gap:20px;
}
}
@media (max-width:800px){
  #gallery{
  -webkit-column-count:2;
  -moz-column-count:2;
  column-count:2;
    
  -webkit-column-gap:20px;
  -moz-column-gap:20px;
  column-gap:20px;
}
}
@media (max-width:600px){
  #gallery{
  -webkit-column-count:1;
  -moz-column-count:1;
  column-count:1;
}  
}
#gallery img,#gallery video {
  width:100%;
  height:auto;
  margin: 4% auto;
  box-shadow:-3px 5px 15px #000;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.modal-img,.model-vid{
  width:100%;
  height:auto;
}
.modal-body{
  padding:0px;
}
.modal {
  display: block!important;
}

.odd {
  background-color: #fdf0e5;
}